export default {
    light: "#ffffff",
    black: "#222222",
    neutral: "#34313F",
  
    primary: "#F44336",
    secondary: "#F44336",
  
    error: "#FF5252",
    info: "#38afff",
    success: "#4CAF50",
    warning: "#ffc830",
  };
  